import React from "react";
import Header from "Layout/Header/Index";
import Footer from "Layout/Footer/Index";
import CtaSection from "Layout/CtaSection/Index";
import { Link } from "react-router-dom";
import ScrollingText from "Components/Other/ScrollingText/Index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { img } from "Assets/Img/index.js";
import FeatureProject from "Layout/FeatureProject/Index";
import DevelopmentProcessCard from "Components/Other/DevelopmentProcessCard/Index";
import TechStackTag from "Components/Other/TechStackTag/Index";
import BenefitProcessItem from "Components/Other/BenefitProcessItem/Index";
import OurClientSection from "Layout/OurClientSection/Index";

const techStackData = [
  {
    id: 1,
    name: "React",
  },
  {
    id: 2,
    name: "Angular",
  },
  {
    id: 3,
    name: "Javascript",
  },
  {
    id: 4,
    name: "HTML5",
  },
  {
    id: 5,
    name: "Angular",
  },
  {
    id: 6,
    name: "React",
  },
  {
    id: 7,
    name: "Angular",
  },
  {
    id: 8,
    name: "Javascript",
  },
  {
    id: 9,
    name: "HTML5",
  },
  {
    id: 10,
    name: "Node Js",
  }
]

const Index = () => {
  return (
    <div className="app-service">
      <Header />
      <main className="main">
        <section className="hero hero--app-service d-flex align-items-center text-center">
          <div className="page-container">
            <div className="hero__inner-content">
              <h2>
                <span className="clr-secondary">Transform Your Business</span>{" "}
                With Our Full Cycle App Development Services
              </h2>
              <p className="mt-1">
                Right From Ideation, Prototyping, To Rapidly Launching An MVP In
                The Market, We Not Only Streamline The Entire Process But Stick
                With You Till The Very End.
              </p>
              <Link to="" className="btn btn--primary mt-1 mt-md-2">
                Schedule The Call
              </Link>
            </div>
          </div>
        </section>

        <ScrollingText text="Mobile apps" />

        <section className="app-service-feature my-6 my-md-8">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={6}>
                <h2>
                  <span className="clr-secondary">We Help Small Teams</span> Do
                  Big Things lorem ipsum dolor sit
                </h2>
              </Col>
            </Row>

            <Row className="mt-2 mt-md-2 gx-3 gx-md-4 gx-lg-5">
              <Col xs={12} sm={12} md={6}>
                <figure>
                  <img
                    className="img-fluid"
                    src={img.appServiceFeature}
                    alt="app service about"
                  />
                </figure>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <p className="mt-1 mt-md-0">
                  Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                  Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                  Aliquyam Erat, Sed Diam Voluptua.
                </p>
                <p className="mt-1">
                  At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet
                  Clita Kasd Gubergren, No Sea Takimata Sanctus Est Lorem
                </p>
                <Link to="/" className="btn btn--primary mt-2 mt-md-2">
                  Schedule The Call
                </Link>
              </Col>
            </Row>
          </div>
        </section>

        <section className="development-process py-6 py-md-8">
          <div className="page-container">
            <div className="section-header m-auto text-center">
              <h2>
                We Take Your Product From
                <span className="clr-secondary">Concept to Scale</span>
              </h2>
              <p className="mt-1">Right From Ideation, Prototyping, To Rapidly Launching An MVP In The Market, We Not Only Streamline The Entire Process But Stick With You Till The Very End.</p>
            </div>

            <div className="development-process__main mt-6 mt-md-8 text-center">
              <Row className="development-process__row gy-5 gy-md-0">
                <Col xs={12} sm={12} md={4}>
                  <DevelopmentProcessCard serialNumber="1" headingTitle="Discovery" />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <DevelopmentProcessCard serialNumber="2" headingTitle="Planning" />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <DevelopmentProcessCard serialNumber="3" headingTitle="Development" />
                </Col>
              </Row>
              <Link to="" className="btn btn--primary mt-3 mt-md-5">Schedule The Call</Link>
            </div>
          </div>
        </section>
        <section className="feature-poster feature-poster--app-service">
        </section>

        <section className="our-technology-stack my-6 my-md-8">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={6}>
                <h2><span className="clr-secondary">Technologies We</span> Use Do Big Things Lorem Ipsum Dolor Sit</h2>
              </Col>
            </Row>

            <Row className="gx-3 gx-md-4 gx-lg-5 gy-5 mt-2 mt-md-2">
              {
                techStackData.map((item) => (
                  <Col xs={6} sm={4} md={3} lg={2} key={item.id}>
                    <TechStackTag text={item.name} />
                  </Col>
                ))
              }
            </Row>
          </div>
        </section>

        <section className="benefit-process py-6 pt-md-8 pb-md-6">
          <div className="page-container">
            <Row >
              <Col xs={12} sm={12} md={6}>
                <h2><span className="clr-secondary">Our Team Assists You</span> Through Full Cycle Mobile App Development Services.</h2>
                <p className="mt-1">We Use The Lean Startup Approach And Scrum Framework To Build Digital Products That Generate Value For Companies And Users.</p>
                <Link to="" className="btn btn--primary mt-2 mt-md-3">Schedule The Call</Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 5, offset: 1 }} className="benefit-process__line-col mt-5 mt-md-0">
                <BenefitProcessItem title="Validate Early" paragraph="We Use The Lean Startup Approach And Scrum Framework To Build Digital Products That Generate Value For Companies And Users." />

                <BenefitProcessItem title="Launch First" paragraph="We Use The Lean Startup Approach And Scrum Framework To Build Digital Products That Generate Value For Companies And Users." />

                <BenefitProcessItem title="Save Money" paragraph="We Use The Lean Startup Approach And Scrum Framework To Build Digital Products That Generate Value For Companies And Users." />
              </Col>
            </Row>
          </div>
        </section>
        <OurClientSection/>
        <FeatureProject />
      </main>
      <CtaSection />
      <Footer />
    </div>
  );
};

export default Index;
