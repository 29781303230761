import React from "react";
import Header from "Layout/Header/Index";
import Hero from "Components/Other/HeroHomepage/Index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import CountRow from "Components/Other/CounterRow/Index";
import SingleService from "Components/Other/SingleService/Index";
import FeatureProject from "Layout/FeatureProject/Index";
import TestimonialSection from "Components/Other/TestimonialSection/Index";
import {blogImg} from "Assets/Img/index";
import BlogCard from "Components/Other/BlogCard/Index";
import CtaSection from "Layout/CtaSection/Index";
import Footer from "Layout/Footer/Index";

const Home = () => {
  return (
    <div className="homepage">
      <Header />
      <main className="main">
        <Hero />
        <section className="feature-poster"></section>
        <section className="about-company py-6 py-md-8">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={6}>
                <p>About the company</p>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <h3 className="about-company__title">
                  No More Backtracking, Double-Selling, Triple-Listing, Multiple
                  Log-Ins Or Anything Else That's Wasting Your Time.
                </h3>
                <Link
                  className="btn btn--primary mt-1 mt-md-2"
                  to="#"
                  role="button"
                >
                  More About us
                </Link>

                <div className="counter-area mt-3 mt-md-4">
                  <CountRow end="20" suffix="+" title="About the company" />
                  <CountRow end="140" suffix="" title="About the company" />
                  <CountRow end="18" suffix="" title="About the company" />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="services-we-offer py-6 py-md-8">
          <div className="page-container">
            <header className="services-we-offer__header">
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <p className="services-we-offer__paragraph">
                    Services We Provide
                  </p>
                  <h1 className="services-we-offer__title">
                    Services that out to us that we provide
                  </h1>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={{ span: 2, offset: 4 }}
                  className="d-flex align-items-end justify-content-md-center"
                >
                  <Link to="#" className="btn btn--primary mt-1" role="button">
                    View Services
                  </Link>
                </Col>
              </Row>
            </header>
            <main className="services-we-offer__main mt-4 mt-8">
              <Row className="gy-md-0 gx-xl-0">
                <Col xs={12} sm={12} md={6} xl={5}>
                  <SingleService
                    title="Web Application"
                    paragraph="Cassette Is Where Creativity Intersects With Communications Strategy, And Integrated Campaigns Are Delivered Via The Most"
                  />
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  xl={{ span: 5, offset: 2 }}
                  className="mt-5 mt-md-0"
                >
                  <SingleService
                    title="Mobile Apps"
                    paragraph="Cassette Is Where Creativity Intersects With Communications Strategy, And Integrated Campaigns Are Delivered Via The Most"
                  />
                </Col>

                <Col xs={12} sm={12} md={6} xl={5} className="my-5 my-md-8">
                  <SingleService
                    title="Web Application"
                    paragraph="Cassette Is Where Creativity Intersects With Communications Strategy, And Integrated Campaigns Are Delivered Via The Most"
                  />
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  xl={{ span: 5, offset: 2 }}
                  className="my-md-8"
                >
                  <SingleService
                    title="Mobile Apps"
                    paragraph="Cassette Is Where Creativity Intersects With Communications Strategy, And Integrated Campaigns Are Delivered Via The Most"
                  />
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={5}
                  className="mt-5 mt-md-0"
                >
                  <SingleService
                    title="Web Application"
                    paragraph="Cassette Is Where Creativity Intersects With Communications Strategy, And Integrated Campaigns Are Delivered Via The Most"
                  />
                </Col>
              </Row>
            </main>
          </div>
        </section>
        <FeatureProject/>
        
        <TestimonialSection />

        <section className="blog my-6 my-md-10">
          <div className="page-container">
            <header className="blog__header mb-5 mb-md-5">
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <h1>News And Articles Understand</h1>
                  <p className="mt-1">
                    Cassette Is Where Creativity Intersects With Communications
                    Strategy, And Integrated Campaigns Are Delivered Via The
                    Most
                  </p>
                </Col>
              </Row>
            </header>
            <main className="blog__main">
              <Row className="gx-lg-5">
                <Col xs={12} sm={12} md={6}>
                  <BlogCard
                    src={blogImg.blog1}
                    alt="Digital solution"
                    title="Digital Solution To Face The Problem Of Overcrowded Shelters And
              Their Pets."
                    publishDate="October 20, 2022"
                  />
                </Col>

                <Col xs={12} sm={12} md={6} className="mt-5 mt-md-0">
                  <BlogCard
                    src={blogImg.blog2}
                    alt="Digital solution"
                    title="Digital Solution To Face The Problem Of Overcrowded Shelters And
              Their Pets."
                    publishDate="October 20, 2022"
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                <Link to="#" className="btn btn--primary mt-2 mt-md-6">
                  View All Articles
                </Link>
              </div>
            </main>
          </div>
        </section>
        <CtaSection />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
