import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TestimonialCard from "Components/Other/TestimonialCard/Index";
import { img } from "Assets/Img/index";
import { Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const Index = () => {
  return (
    <div className="testimonial pb-6 pt-2 pt-md-6 pb-md-8">
      <div className="page-container">
        <header className="testimonial__header pb-2 pb-md-2">
          <Row>
            <Col xs={12} sm={12} md={6}>
              <h1>Our Clients out to us that we provide</h1>
              <p className="mt-2">
                Cassette Is Where Creativity Intersects With Communications
                Strategy, And Integrated Campaigns Are Delivered Via The Most
              </p>
            </Col>
          </Row>
        </header>
        <main className="testimonial__main mt-2 mt-md-4">
          <Swiper
            modules={[Pagination, Scrollbar]}
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            <SwiperSlide>
              <TestimonialCard testimonialContent="Everything You Need For A Digitally Driven Brand. Defined Proposition. Conceptual Realization. Logo, Type, Look, Feel, Tone, Movement, Content" personImg={img.testimonialPerson} imgAlt="Jhon Doe" personName="John Doe" personDesignation="Filmora Consulting" />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard testimonialContent="Everything You Need For A Digitally Driven Brand. Defined Proposition. Conceptual Realization. Logo, Type, Look, Feel, Tone, Movement, Content" personImg={img.testimonialPerson} imgAlt="Jhon Doe" personName="John Doe" personDesignation="Filmora Consulting" />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard testimonialContent="Everything You Need For A Digitally Driven Brand. Defined Proposition. Conceptual Realization. Logo, Type, Look, Feel, Tone, Movement, Content" personImg={img.testimonialPerson} imgAlt="Jhon Doe" personName="John Doe" personDesignation="Filmora Consulting" />
            </SwiperSlide>
          </Swiper>
        </main>
      </div>
    </div>
  );
};

export default Index;
