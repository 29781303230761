import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <section className="cta-section pt-6 pt-md-10">
      <div className="cta-section__bg-container pt-2 pb-8 pb-md-12">
        <div className="page-container">
          <div className="w-550 m-auto text-center">
            <h1>Reach Out To Us</h1>
            <p>
              Cassette Is Where Creativity Intersects With Communications
              Strategy, And Integrated Campaigns Are Delivered Via The Most{" "}
            </p>
            <Link to="#" className="btn btn--primary mt-2 mt-md-3" role="button">
              Reach Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
