import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Index = ({ title, paragraph }) => {
    return (
        <div className="benefit-process__item mb-2 mb-md-3">
            <Row className="gx-3 gx-md-4">
                <Col xs={2} className="mt-1">
                    <svg id="Group_1301" data-name="Group 1301" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <circle id="Ellipse_45" data-name="Ellipse 45" cx="18" cy="18" r="18" fill="#0da34e" />
                        <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M5.1,15.545.22,10.669a.75.75,0,0,1,0-1.061L1.281,8.548a.75.75,0,0,1,1.061,0l3.285,3.285L12.662,4.8a.75.75,0,0,1,1.061,0l1.061,1.061a.75.75,0,0,1,0,1.061L6.157,15.545A.75.75,0,0,1,5.1,15.545Z" transform="translate(10.529 7.799)" fill="#fff" />
                    </svg>
                </Col>
                <Col xs={10}>
                    <h3>{title}</h3>
                    <p>{paragraph}</p>
                </Col>
            </Row>
        </div>
    )
}

export default Index