import React from 'react'

const Index = ({serialNumber, title, paragraph}) => {
  return (
    <div className="about-us__steps mt-5 mt-md-6 mt-lg-8">
      <h1>{serialNumber}</h1>
      <h3>{title}</h3>
      <p>{paragraph}</p>
    </div>
  )
}

export default Index