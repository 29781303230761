import React from "react";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/Col";
import footerLogo from "Assets/Img/footer-logo.svg";
import { Link } from "react-router-dom";

const Index = () => {
  const socialMedia = [
    {
      id: 1,
      name: "Instagram",
      path: "https://www.instagram.com/yourhustlerinc/",
    },
    {
      id: 2,
      name: "Linkedin",
      path: "https://www.linkedin.com/company/yourhustlerinc",
    },
    {
      id: 3,
      name: "Twitter",
      path: "",
    },
    {
      id: 4,
      name: "Facebook",
      path: "https://www.facebook.com/yourhustlerinc",
    },
  ];

  const footerLink = [
    {
      name: "About",
      "about": [
        {
          id: 1,
          name: "Company",
          path: "/",
        },
        {
          id:2,
          name:"Careers", 
          path:"/",
        },
        {
          id:3,
          name:"Values",
          path:"/",
        },
        {
          id:4,
          name:"team",
          path:"/",
        }
      ],
    },
    {
      name:"Resources", 
      "resources":[
        {
          id:1,
          name:"Company",
          path:"/",
        },
        {
          id:2,
          name:"Careers",
          path:"/",
        },
        {
          id:3,
          name:"Values",
          path:"/",
        },
        {
          id:4,
          name:"team",
          path:"/",
        }
      ]
    },
    {
      name:"Legal",
      "legal":[
        {
          id:1,
          name:"Privacy Policy", 
          path:"/",
        },
        {
          id:2,
          name:"Legal",
          path:"/",
        }
      ]
    }
  ];

  return (
    <footer className="footer py-4 py-md-8">
      <div className="page-container">
        <Row>
          <Col xs="12" sm={12} md={3}>
            <img
              className="img-fluid footer__logo"
              src={footerLogo}
              alt="mernverse logo"
            />
            <p className="mt-1 footer__paragraph">
              Casset Is Where Creativity Intersects With Communications
              Strategy, And Integrated Campaigns Are Delivered Via The Most{" "}
            </p>
          </Col>
          <Col xs="12" sm="12" md={9} lg={{ span: 8, offset: 1 }}>
            <Row>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-0">
                <h5 className="footer__link-title">About</h5>
                <ul className="mt-md-1">
                 {
                   footerLink[0].about.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className="footer__link">
                        {item.name}
                      </Link>
                    </li>
                   ))
                 }
                </ul>
              </Col>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-0">
                <h5 className="footer__link-title">Resources</h5>
                <ul className="mt-md-1">
                {
                   footerLink[1].resources.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className="footer__link">
                        {item.name}
                      </Link>
                    </li>
                   ))
                 }
                </ul>
              </Col>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-0">
                <h5 className="footer__link-title">Services</h5>
                <ul className="mt-md-1">
                {
                   footerLink[1].resources.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className="footer__link">
                        {item.name}
                      </Link>
                    </li>
                   ))
                 }
                </ul>
              </Col>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-4">
                <h5 className="footer__link-title">Social</h5>
                <ul className="mt-md-1">
                  {socialMedia.map((item) => (
                    <li key={item.id}>
                      <a
                        href={item.path}
                        rel="noreferrer"
                        target="_blank"
                        className="footer__link"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-4">
                <h5 className="footer__link-title">Legal</h5>
                <ul className="mt-md-1">
                {
                  footerLink[2].legal.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className="footer__link">
                        {item.name}
                      </Link>
                    </li>
                  ))
                }
                </ul>
              </Col>
              <Col xs="6" sm={6} md={4} className="mt-2 mt-md-4">
                <h5 className="footer__link-title">Technologies</h5>
                <ul className="mt-md-1">
                {
                   footerLink[0].about.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className="footer__link">
                        {item.name}
                      </Link>
                    </li>
                   ))
                 }
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mt-md-6 d-flex flex-column-reverse flex-md-row">
          <Col xs={12} sm={12} md={3}>
            <p className="footer__paragraph mt-1 mt-md-0">
              All Rights Reserved <br />
              Designed By YourHustler
            </p>
          </Col>
          <Col xs={12} sm={12} md={9} lg={{ span: 6, offset: 1 }}>
            <p className="footer__paragraph">Subscribe To Newsletter</p>
            <form className="d-flex subscribe-form mt-1">
              <input
                className="subscribe-form-control"
                type="email"
                autoComplete="off"
                placeholder="Enter Your Email"
                required
              />
              <button type="submit" className="btn btn--primary">
                Subscribe
              </button>
            </form>
            <p className="footer__paragraph mt-2">
              Subscribe To Our Notifications. For Privacy Reason, Please Enter
              An Email Other Than The One You Used To Purchase One key. We Also
              Regularly Delete Order Information To Protect Your Privacy.
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Index;
