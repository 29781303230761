import React from "react";
import Header from "Layout/Header/Index";
import CtaSection from "Layout/CtaSection/Index";
import Footer from "Layout/Footer/Index";
import AboutUs from "Layout/AboutUsSection/Index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import clutchLogo from "Assets/Img/clutch-logo.svg";
import ScrollingText from "Components/Other/ScrollingText/Index";
import OurServiceGroup from "Components/Other/OurServicesGroup/Index";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <>
      <Header />
      <main className="main">
        <section className="services-hero pt-8 pt-md-14 pb-md-3 pb-4">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={10} lg={7}>
                <h1>
                  <span className="clr-secondary">Services</span> with lorem
                  ipsum that out
                </h1>
              </Col>
              <Col xs={12} sm={12} md={9} lg={6}>
                <p className="mt-1">
                  Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                  Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                  Aliquyam Erat, Sed Diam
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                className="d-flex justify-content-md-end"
                md={{ span: 3, offset: 9 }}
              >
                <figure className="mt-4 mt-md-6 mt-lg-10">
                  <img
                    src={clutchLogo}
                    className="img-fluid"
                    alt="clutch logo"
                  />
                </figure>
              </Col>
            </Row>
          </div>
        </section>
        <ScrollingText text="Mobile apps" />

        <section className="our-services my-6 my-md-8">
          <div className="page-container">
            <Row className="gx-0 gx-md-5 gx-lg-5">
              <Col xs={12} sm={12} md={6} lg={5}>
                <OurServiceGroup />
              </Col>
              <Col xs={12} sm={12} md={6} lg={{ span: 6, offset: 1 }} className="mt-2" >
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                  Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                  Aliquyam Erat, Sed Diam Voluptua.
                </p>
                <p>
                  At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet
                  Clita Kasd Gubergren, No Sea Takimata Sanctus Est Lorem
                </p>
                <Link to="/" className="btn btn--primary mt-2 mt-md-3">
                  Learn More
                </Link>
              </Col>
            </Row>
          </div>
        </section>
        <AboutUs />
      </main>
      <CtaSection />
      <Footer />
    </>
  );
};

export default Index;
