import React from "react";
import Navbar from "Layout/Navbar/Index";
import Sidemenu from "Layout/Sidemenu/Index";
import NavLogo from "Components/Other/NavLogo/Index";
import { useEffect, useState } from "react";

function Index() {
  const [scroll, setScroll] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleSideMenu = () => setIsSideMenuOpen(!isSideMenuOpen);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let lastScroll = window.scrollY;
      setScroll(lastScroll > 50);
    });

    window.addEventListener("load", () => {
      setScroll(window.scrollY > 50)
    })
  });

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = isSideMenuOpen ? "hidden" : "auto";
    }
  }, [isSideMenuOpen]);

  return (
    <header className={scroll ? "header header--sticky" : "header"}>
      <div className="page-container">
        <div className="header__wrapper">
          <NavLogo />
          <div className="d-flex">
            <Navbar />
            <button
              type="button"
              className="navbar-toggle"
              onClick={toggleSideMenu}
              aria-controls="sidemenu"
            >
              <span className="navbar-toggle-block">
                <span className="navbar-toggle-bar"></span>
                <span className="navbar-toggle-bar"></span>
                <span className="navbar-toggle-bar"></span>
              </span>
            </button>
          </div>
        </div>
        <Sidemenu isSideMenuOpen={isSideMenuOpen} />
        <div
          className={isSideMenuOpen ? "overlay overlay--active" : "overlay"}
          onClick={toggleSideMenu}
        ></div>
      </div>
    </header>
  );
}

export default Index;
