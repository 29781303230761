import React from 'react';
import Home from 'Pages/Home/Index';
import About from 'Pages/About/Index';
import Contact from 'Pages/Contact/Index';
import Service from 'Pages/Service/Index';
import AppService from 'Pages/AppService/Index';
import {Routes, Route } from 'react-router-dom';
import './Assets/Scss/App.scss';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>} />
        <Route path="/service" element={<Service/>}/>
        <Route path="/app-service" element={<AppService/>} />
      </Routes>      
    </div>
  );
}

export default App;