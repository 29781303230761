import React from "react";

const ourServices = [
  {
    id: 1,
    name: "Web design",
  },
  {
    id: 2,
    name: "Mobile Apps",
  },
  {
    id: 3,
    name: "Web design",
  },
  {
    id: 4,
    name: "Mobile Apps",
  },
];

const Index = () => {
  return (
    <div className="our-services__group">
      {ourServices.map((service) => (
        <div className="our-service__single py-1 py-lg-2" key={service.id}>
          <small className="our-service__serial-number">{`0 ${service.id}`}</small>
          <h2 className="our-service__name pl-2">{service.name}</h2>
        </div>
      ))}
    </div>
  );
};

export default Index;
