import React from 'react';
import {Link} from 'react-router-dom';
import logo from 'Assets/Img/mernverse-logo.svg';
import logoBlack from 'Assets/Img/mernverse-logo-black.svg';

function Index() {
  return (
    <div className="header__logo">
        <Link to="/">
            <img className="img-fluid header__logo-img header__logo--white" src={logo} alt="Mearnverse logo" />
            <img className="img-fluid header__logo-img header__logo--black" src={logoBlack} alt="Mernverse logo black" />
        </Link>
    </div>
  )
}

export default Index