import React from "react";
import Header from "Layout/Header/Index";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Footer from "Layout/Footer/Index";
import ContactForm from "Components/Common/ContactForm/Index";
import ContactHighlight from "Components/Other/ContactHighlight/Index";
import { TbQuestionMark } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { IoMdPhonePortrait } from "react-icons/io";

const Index = () => {
  return (
    <>
      <Header />
      <main className="contact">
        <section className="contact__hero-area mt-8 mb-6 my-md-12">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={6} lg={5}>
                <h1>Contact us</h1>
                <p className="mt-1">
                  Looking For A Quick Answer To Your Question? Check Out Our
                  FAQs. For Everything Else.
                </p>
                <p className="mt-1">
                  For Everything Else, Drop Us A Line And We'll Get Back To You.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={{ span: 5, offset: 2 }}
              >
                <ContactForm />
              </Col>
            </Row>
          </div>
        </section>

        <section className="my-6 my-md-8">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={4}>
                <ContactHighlight
                  icon={
                    <TbQuestionMark
                      id="question-icon"
                      size={48}
                      style={{ stroke: "black" }}
                    />
                  }
                  title="Questions"
                  label="info@mernverse.com"
                  mailto="mailto:info@mernverse.com"
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <ContactHighlight
                  icon={<BiSupport size={42} style={{ fill: "black" }} />}
                  title="Press"
                  label="info@mernverse.com"
                  mailto="mailto:info@mernverse.com"
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <ContactHighlight
                  icon={
                    <IoMdPhonePortrait
                      id="phone-icon"
                      size={42}
                      style={{ fill: "black" }}
                    />
                  }
                  title="Phone"
                  label="info@mernverse.com"
                  mailto="mailto:info@mernverse.com"
                />
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Index;
