import React from "react";

const Index = ({ src, alt, title, paragraph }) => {
  return (
    <div className="feature-project__card">
      <figure className="feature-project__card-img-container">
        <img className="img-fluid" src={src} alt={alt} />
      </figure>

      <div className="feature-project__card-content mt-1 mt-md-2">
        <h4>{title}</h4>
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export default Index;
