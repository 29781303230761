import React from 'react'

const Index = ({text}) => {
  return (
    <div className="tech-stack-tag">
      {text}
    </div>
  )
}

export default Index