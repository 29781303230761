import React from 'react'

const Index = ({serialNumber, headingTitle}) => {
    return (
        <div className="development-process__card text-center">
            <div className="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                    <g id="Group_1301" data-name="Group 1301" transform="translate(-669 -7364)">
                        <circle id="Ellipse_45" data-name="Ellipse 45" cx="18" cy="18" r="18" transform="translate(669 7364)" fill="#0da34e" />
                        <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M5.1,15.545.22,10.669a.75.75,0,0,1,0-1.061L1.281,8.548a.75.75,0,0,1,1.061,0l3.285,3.285L12.662,4.8a.75.75,0,0,1,1.061,0l1.061,1.061a.75.75,0,0,1,0,1.061L6.157,15.545A.75.75,0,0,1,5.1,15.545Z" transform="translate(679.529 7371.799)" fill="#fff" />
                    </g>
                </svg>
            </div>
            <div className="wrapper mt-0">
                <p className="development-process__card-serial-number text-start mt-0">{serialNumber}</p>
                <h3 className="development-process__card-heading pl-1">{headingTitle}</h3>
            </div>

        </div>
    )
}

export default Index