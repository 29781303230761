import React from "react";
import Logo from "Assets/Img/mernverse-logo.svg";
import { NavLink, Link } from "react-router-dom";
import { HiOutlineHome } from "react-icons/hi";
import { RiContactsLine } from "react-icons/ri";

import {
  FaServicestack,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
} from "react-icons/fa";
import { TiInfoLargeOutline } from "react-icons/ti";

const Index = ({ isSideMenuOpen }) => {
  const navItem = [
    {
      id:1,
      name:"Home",
      path:"/",
      icon:<HiOutlineHome/>
    },
    {
      id:2,
      name:"About",
      path:"/about",
      icon:<TiInfoLargeOutline/>
    },
    {
      id:3,
      name:"Service",
      path:"/service",
      icon:<FaServicestack/>
    },
    {
      id:4,
      name:"Contact",
      path:"/contact",
      icon:<RiContactsLine/>
    }
  ]
  const socialMedia = [
    {
      id:1,
      name: "Instagram",
      href:"https://www.instagram.com/yourhustlerinc/",
      icon: <FaInstagram />,
    },
    {
      id:2,
      name: "Linkedin",
      href:"https://www.linkedin.com/company/yourhustlerinc",
      icon: <FaLinkedin />,
    },
    {
      id:3,
      name: "Twitter",
      href:"/",
      icon: <FaTwitter />,
    },
    {
      id:4,
      name: "Facebook",
      href:"https://www.facebook.com/yourhustlerinc",
      icon: <FaFacebook />,
    },
  ];

  return (
    <aside className={isSideMenuOpen ? "sidemenu sidemenu--is-open" : "sidemenu"} id="sidemenu">
      <div className="sidemenu__top-content m-1 m-sm-2">
        <Link to="/">
          <img className="img-fluid header__logo-img" src={Logo} alt="mernverse logo" />
        </Link>
      </div>
      <nav className="sidemenu__nav m-1 mt-2 m-sm-2">
        <h5 className="sidemenu__nav-title">Categories Menu</h5>
        <ul className="sidemenu__nav-list mt-1">
        {
          navItem.map((item)=>(
            <li className="sidemenu__nav-item" key={item.id}>
              <NavLink to={item.path} className="sidemenu__link">
                {item.icon}
                <span>{item.name}</span>
              </NavLink>
            </li>
          ))
        }
        </ul>
      </nav>

      <hr className="mx-1 mx-md-2" />

      <div className="sidemenu__social-media m-1 m-md-2">
        <h5 className="sidemenu__nav-title">Social media</h5>
        <ul className="sidemenu__social-list my-1 d-flex align-items-center">
          {socialMedia.map((item) => (
            <li className="sidemenu__social-item" key={item.id}>
              <a href={item.href} rel="noreferrer" target="_blank">
                {item.icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Index;
