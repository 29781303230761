import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Index = ({ src, alt, title, publishDate }) => {
  return (
    <div className="blog__card">
    <figure className="img-container-hover-scale">
        <img className="img-fluid" src={src} alt={alt} />
    </figure>
    
      <div className="blog__card-footer p-1 p-md-2 pt-2">
        <Row>
          <Col xs={12} sm={11} md={10}>
            <p>{title}</p>
            <small className="blog__publish-date">{publishDate}</small>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Index;
