import React from "react";
import AboutUsSteps from "Components/Other/AboutUsSteps/Index";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <section className="about-us py-6 py-md-8">
      <div className="page-container">
        <div className="section-header text-center">
          <h1>
            <span className="clr-secondary">About Us</span> that out to us that
            we lorem ipsum
          </h1>
        </div>
        <div className="about-us__main">
          <AboutUsSteps
            serialNumber="1"
            title="Research  Strategy"
            paragraph="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
          />
          <AboutUsSteps
            serialNumber="2"
            title="Discovery Concept"
            paragraph="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
          />
          <AboutUsSteps
            serialNumber="3"
            title="Design Prototype"
            paragraph="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
          />
          <AboutUsSteps
            serialNumber="4"
            title="Handoff Development"
            paragraph="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
          />
        </div>
        <div className="text-center">
          <Link to="/" className="btn btn--primary mt-3 mt-md-6 text-center">
            Build Your Project
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Index;
