import React from "react";

const Index = ({ text }) => {
  return (
    <div className="marque-container">
      <div className="marque-wrapper">
        <div className="marque-banner">
          <div className="marque-banner__track">
            {Array.apply(null, { length: 25 }).map((item, index) => (
              <p className="marque-item" key={index}>
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
