import React from "react";
import ProjectCard from "Components/Other/ProjectCard/Index";
import { projectImg } from "Assets/Img/index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export const Index = () => {
  return (
    <section className="feature-project my-6 my-8">
      <div className="page-container">
        <header className="feature-project__header">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <h1>Our project</h1>
              <p className="mt-1 mt-md-2">
                Cassette Is Where Creativity Intersects With Communications
                Strategy, And Integrated Campaigns Are Delivered Via The Most
              </p>
            </Col>
          </Row>
        </header>
        <main className="feature-project__main mt-4 mt-md-4">
          <div className="d-flex justify-content-end text-align-end mb-1 mb-md-2">
            <h3 className="feature-project__main-title">40+ Projects</h3>
          </div>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <ProjectCard
                src={projectImg.project1}
                alt="styling zone"
                title="Styling Zone"
                paragraph="A New Type Of Ecommerce For High-End Beauty Products"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={{ span: 5, offset: 1 }}
              className="mt-5 mt-md-0"
            >
              <ProjectCard
                src={projectImg.project2}
                alt="styling zone"
                title="Styling Zone"
                paragraph="A New Type Of Ecommerce For High-End Beauty Products"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={5} className="my-5">
              <ProjectCard
                src={projectImg.project3}
                alt="styling zone"
                title="Styling Zone"
                paragraph="A New Type Of Ecommerce For High-End Beauty Products"
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={{ span: 6, offset: 1 }}
              className="mb-3 my-md-5"
            >
              <ProjectCard
                src={projectImg.project4}
                alt="styling zone"
                title="Styling Zone"
                paragraph="A New Type Of Ecommerce For High-End Beauty Products"
              />
            </Col>
          </Row>
          <div className="text-center mt-1 mt-md-3">
            <Link to="/" className="btn btn--secondary" role="button">
              View All Portfolio
            </Link>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Index;