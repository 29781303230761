import React from "react";
import { Link } from "react-router-dom";

const Index = ({ title, paragraph }) => {
    return (
        <div className="single-service">
            <div className="single-service__header d-flex justify-content-between align-items-center">
                <h2 className="single-service__title">{title}</h2>
                <Link to="#" className="single-service__link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="49.217" height="49.218" viewBox="0 0 49.217 49.218">
                        <path id="Icon_awesome-arrow-down" data-name="Icon awesome-arrow-down" d="M32.452,17.237,34.2,18.983a1.88,1.88,0,0,1,0,2.667L18.912,36.944a1.88,1.88,0,0,1-2.667,0L.952,21.65a1.88,1.88,0,0,1,0-2.667L2.7,17.237a1.89,1.89,0,0,1,2.7.031l9.031,9.48V4.138A1.884,1.884,0,0,1,16.316,2.25h2.517a1.884,1.884,0,0,1,1.888,1.888v22.61l9.031-9.48A1.877,1.877,0,0,1,32.452,17.237Z" transform="translate(22.983 51.089) rotate(-135)" fill="#fff" />
                    </svg>
                </Link>
            </div>
            <div className="single-services__main mt-2">
                <p className="single-service__paragraph">{paragraph}</p>
                <Link to="#" className="btn btn--secondary mt-1 mt-md-2">View Services</Link>
            </div>
        </div>
    );
};

export default Index;