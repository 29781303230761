import React from 'react';
import Counter from "Components/Other/Counter/Index";

const Index = ({title, description, end, suffix }) => {
  return (
    <div className="about__highlight-card">
      <h3 className="pb-1 mb-1 about__highlight-card-title">{title}</h3>
      <Counter className="count" end={end} suffix={suffix} />
      <p>{description}</p>
    </div>
  )
}

export default Index