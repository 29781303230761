import React from 'react'; 
import Counter from 'Components/Other/Counter/Index'; 

const Index = ({end, suffix, title}) => {
  return (
    <div className="counter-row py-2 py-md-3">
      <Counter className="count" end={end} suffix={suffix}/>
      <div className="Counter-row__paragraph d-flex align-items-end">
          <p>{title}</p>
      </div>
    </div>
  )
}

export default Index