import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Index = ({
  imgSrc,
  imgAlt,
  figureCaption,
  paragraphText,
  linkTo,
  linkText,
}) => {
  return (
    <section className="join-us-section my-6 my-md-10">
      <div className="page-container">
        <Row className="gx-0 gx-md-3 gx-lg-5">
          <Col xs={12} sm={12} md={12} lg={7}>
            <h1>
              <span className="clr-secondary">Join Us</span> that out to us that
              we lorem ipsum
            </h1>
          </Col>
          <Col xs={12} sm={12} md={6} className="mt-md-3 mt-lg-5">
            <figure>
              <img className="img-fluid" src={imgSrc} alt={imgAlt} />
              <figcaption>{figureCaption}</figcaption>
            </figure>
          </Col>
          <Col xs={12} sm={12} md={6} className="mt-md-3 mt-lg-5">
            {
              paragraphText.map((line, index) => (
                <p key={index} className="mb-1">{line}</p>
              ))
            }
            <Link to={linkTo} className="btn btn--primary mt-2 mt-3">
              {linkText}
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Index;
