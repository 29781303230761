import React from "react";

const Index = ({
  testimonialContent,
  personImg,
  imgAlt,
  personName,
  personDesignation,
}) => {
  return (
    <div className="testimonial__card">
      <p className="testimonial__content">{testimonialContent}</p>
      <div className="testimonial__footer d-flex align-items-center mt-2 mt-md-3">
        <img className="testimonial__person-img" src={personImg} alt={imgAlt} />
        <div className="wrapper">
          <h4 className="testimonial__person-name">{personName}</h4>
          <p className="testimonial__person-designation">{personDesignation}</p>
        </div>
      </div>
    </div>
  );
};

export default Index;