export const img = {
    mernverseLogo: require("./mernverse-logo.svg"),
    featurePoster:
        require("./c7381fc572e59b42ccd3953f9773333fc21fd707-1232x728.jpg"),
    testimonialPerson:require("./jhon-doe.png"),
    footerLogo:require("./footer-logo.svg"),
    aboutHeroPlaceholder:require("./about-hero-placeholder.jpg"),
    aboutHeroPerson:require("./about-hero-person.png"), 
    clientLogoGroup:require("./client-logo.png"),
    ourTeamMember:require("./our-team.jpg"),
    aboutUsSectionPlaceholder:require("./about-us-section-placeholder.jpg"),
    clutchLogo:require("./clutch-logo.svg"),
    appServiceFeature:require("./app-service-feature.jpg")
};

export const projectImg = {
    project1: require("./project/9f0a18769f6d027324c2e452413f3b06.jpg"),
    project2: require("./project/6225353e21c400bc93c4536d_thumb-bs-launch.jpg"),
    project3: require("./project/ab-1-new.jpg"),
    project4:
        require("./project/6272b155589881691b42a900_thumb-poesial-big-p-800.jpg"),
};

export const blogImg = {
    blog1:require("./blog/blog1.jpg"),
    blog2:require("./blog/blog2.jpg"),
};