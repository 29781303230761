import React from 'react';
import {Link} from "react-router-dom"

const Index = ({icon, title, mailto, label}) => {
  return (
    <div className="contact__highlight">
        {icon}
        <h3>{title}</h3>
        <Link to='#' className="contact__highlight-link"
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    </div>
  )
}

export default Index