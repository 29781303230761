import React from "react";
import Header from "Layout/Header/Index";
import CtaSection from "Layout/CtaSection/Index";
import Footer from "Layout/Footer/Index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { img } from "Assets/Img/index";
import AboutHighlightCard from "Components/Other/AboutHighlightCard/Index";
import {Link} from "react-router-dom";
import ProjectCard from "Components/Other/ProjectCard/Index";
import AboutUsSection from "Layout/AboutUsSection/Index";
import JoinUsSection from "Layout/JoinUsSection/Index";
import OurClientSection from "Layout/OurClientSection/Index";


const About = () => {
  return (
    <>
      <Header />
      <main className="main">
        <section className="about__hero-area mt-8 mb-6 my-md-12">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={7}>
                <h1>
                  <span className="clr-secondary">About Us </span> that out to
                  us that we lorem ipsum
                </h1>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <figure className="about__hero-img my-2 my-md-4">
                  <img
                    src={img.aboutHeroPlaceholder}
                    className="img-fluid"
                    alt="about us hero placeholder"
                  />
                </figure>
              </Col>

              <Col xs={12} sm={{span:9, offset:3}} md={{span:7, offset:5}} lg={{ span: 5, offset: 7 }}>
                <p>
                  We Are Not Artists. Our Goal Is Not To Make Things Pretty, But
                  To Make Beautiful Things That Deliver Concrete, Measurable
                  Results — On Time And On Budget.
                </p>
                <div className="about__hero-footer mt-1 d-flex align-items-center">
                  <img className="img-fluid" src={img.aboutHeroPerson} alt="" />
                  <div className="about__hero-designation">
                    <h6>John Doe</h6>
                    <small>Founder & President</small>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="about__highlight">
          <div className="page-container">
            <div className="section-header text-center">
              <h3 className="about__highlight-heading">
                Any Values That We Have No More Backtracking, Double-Selling,
                Triple-Listing, Multiple Log-ns Or Anything Else That's Wasting
                Your Time.
              </h3>
            </div>
          </div>
          <div className="about__highlight-main mt-4 mt-md-6 py-4 py-6">
            <div className="page-container">
              <Row className="gx-md-5">
                <Col xs={12} sm={12} md={4}>
                  <AboutHighlightCard
                    title="Clients"
                    end="20"
                    suffix="+"
                    description="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
                  />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <AboutHighlightCard
                    title="Departments"
                    end="140"
                    suffix=""
                    description="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
                  />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <AboutHighlightCard
                    title="Number"
                    end="18"
                    suffix=""
                    description="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt"
                  />
                </Col>
              </Row>
              <Link to="/" className="btn btn--primary mt-2 mt-md-5">Build Your Project</Link>
            </div> 
          </div>
        </section>

        <OurClientSection/>
       
        <section className="about__feature-poster"></section>
        <section className="our-team my-6 my-md-10">
          <div className="page-container">
            <Row>
              <Col xs={12} sm={12} md={7}>
                <h1><span className="clr-secondary">Team</span> that out to us that we lorem ipsum</h1>
              </Col>
            </Row>
            <div className="our-team__main">
              <Row className="gx-0 gx-md-3 gx-xl-5">
                <Col xs={12} sm={12} md={4} className="mt-md-4">
                  <ProjectCard src={img.ourTeamMember} alt="Mernverse team members" title="John Doe" paragraph="Founder & President" /> 
                </Col>
                <Col xs={12} sm={12} md={4} className="mt-md-4">
                  <ProjectCard src={img.ourTeamMember} alt="Mernverse team members" title="John Doe" paragraph="Founder & President" /> 
                </Col>
                <Col xs={12} sm={12} md={4} className="mt-md-4">
                  <ProjectCard src={img.ourTeamMember} alt="Mernverse team members" title="John Doe" paragraph="Founder & President" /> 
                </Col>
              </Row>
            </div>
          </div>
        </section>
        <AboutUsSection />
        <JoinUsSection imgSrc={img.aboutUsSectionPlaceholder} imgAlt="what we do" figureCaption="Any Image Description" paragraphText={["Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua.",  "At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. Stet Clita Kasd Gubergren, No Sea Takimata Sanctus Est Lorem"]} linkTo="/about" linkText="Careers at MERNverse"  />
      </main>
      <CtaSection />
      <Footer />
    </>
  );
};

export default About;