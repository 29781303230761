import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Grid, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const Index = () => {
  const phoneRegex = new RegExp(
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
  )
  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Full Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    select: Yup.string().required("Services is Required"),
    phoneNumber:Yup.string().required("Phone Number is Required").matches(phoneRegex, "Invalid phone number").min(10, "Enter 10 numbers")
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    // mode:"onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data, null, 2));
  };

  const servicesData = [
    {
      id: 1,
      label: "Web Application",
    },
    {
      id: 2,
      label: "Mobile Application",
    },
    {
      id: 3,
      label: "Web Application",
    },
  ];

  return (
    <>
      <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="fullName"
                name="fullName"
                label="Full Name"
                variant="standard"
                fullWidth
                margin="dense"
                {...register("fullname")}
                error={errors.fullname ? true : false}
              />
              <Typography
                sx={{ fontSize: 14 }}
                variant="inherit"
                color="textSecondary"
              >
                {errors.fullname?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email Address"
                placeholder="jhon@dmain.com"
                multiline
                variant="standard"
                fullWidth
                margin="dense"
                {...register("email")}
                error={errors.email ? true : false}
              />
              <Typography
                sx={{ fontSize: 14 }}
                variant="inherit"
                color="textSecondary"
              >
                {errors.email?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth={true}
                margin="dense"
                error={errors.select ? true : false}
              >
                <InputLabel htmlFor="service">Services</InputLabel>
                <Select
                  id="service"
                  {...register("select")}
                  onChange={(e) =>
                    setValue("select", e.target.value, { shouldValidate: true })
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {servicesData.map((item) => (
                    <MenuItem key={item.id} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                sx={{ fontSize: 14 }}
                variant="inherit"
                color="textSecondary"
              >
                {errors.select?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                fullWidth
                type="text"
                name="Phone number"
                label="Phone Number"
                variant="standard"
                {...register("phoneNumber")}
                error={errors.phoneNumber ? true : false}
              />
              <Typography
                sx={{ fontSize: 14 }}
                variant="inherit"
                color="textSecondary"
              >
                {errors.phoneNumber?.message}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <button
              type="submit"
              className="btn btn--primary mt-1"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Register
            </button>
          </Box>
      </form>
    </>
  );
};

export default Index;
