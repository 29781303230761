import React from 'react'
import Row from "react-bootstrap/Row";      
import Col from "react-bootstrap/Col";
import { img } from "Assets/Img/index";

const Index = () => {
    return (
        <section className="our-clients my-6 my-md-8">
            <div className="page-container">
                <Row>
                    <Col xs={12} sm={12} md={7}>
                        <h1>We've launched <span className="clr-secondary">50+ projects</span> for clients in Europe and the U.S.</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="mt-4 mt-md-5">
                        <figure>
                            <img className="img-fluid" src={img.clientLogoGroup} alt="clients logo yourhustler works with" />
                        </figure>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Index