import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <section className="hero hero--homepage">
      <div className="page-container">
        <Row>
          <Col xs={12} sm={12} md={7}>
            <h1 className="hero__title">Product Design Agency</h1>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={5}
            className="d-flex flex-column-reverse flex-md-column"
          >
            <div className="btn-wrapper mt-2 mt-md-1 ml-md-5">
              <Link to="/" role="button" className="btn btn--hero">
                learn more
              </Link>
            </div>

            <p className="hero__paragraph mt-1 mt-lg-7 mt-xl-9">
              Cassette Is Where Creativity Intersects With Communications
              Strategy, And Integrated Campaigns Are Delivered Via The Most
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Index;
