import React from "react";
import {Link, NavLink } from "react-router-dom";

function Index() {
  const navItem = [
    {
      id:1,
      name:"Home",
      path:"/",
    },
    {
      id:2,
      name:"About",
      path:"/about",
    },
    {
      id:3,
      name:"Service",
      path:"/service",
    },
    {
      id:4,
      name:"Contact",
      path:"/contact"
    }
  ]

  return (
    <div className="navbar-wrapper">
      <nav className="nav">
        <ul className="nav__list">
        {
          navItem.map((item)=>(
            <li className="nav__list-item" key={item.id}>
              <NavLink className="nav__link" to={item.path}>
                {item.name}
              </NavLink>
            </li>
          ))
        }
        <li>
          <Link to="/service" role="button" className="btn btn--primary">Let's Talk</Link>
        </li>
        </ul>
      </nav>
    </div>
  );
}

export default Index;